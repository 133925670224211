$font-size: 1rem;
$color-text: #E8EBEC;
$page-bg-color: #EAEBEC;
$color-dark: #57666f;
$color-gray: #737373;
$color-primary: #E1E1E1;
$color-placeholder: #939495;
$color-bg-tag: #dcd9d9;
$color-text-dark: #555;


html {
    font-size: $font-size;
    background: linear-gradient(154deg, #FCEFE4 20%, #dbecff 100%) no-repeat center;
    font-family: Arial, Helvetica, sans-serif;
}

body {
    padding: 0;
    margin: 0;
}

* {
    border: none;
}

h1, h2, h3, h4, h5, h6 {
    margin: 0;
    padding: 0;
}

.page {
    display: flex;
    justify-items: center;
    flex-direction: column;
}

p {
    margin: 0 0 12px;
    padding: 0;
}

.title {
    font-family: 'Times New Roman', Times, serif;
    margin: 16px 0 7px;
    font-size: 51px;
    text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);
    color: $color-text;
}

.sub-title {
    font-family: 'Times New Roman', Times, serif;
    font-style: italic;
    margin: 0 14px 90px;
    font-size: 34px;
    text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);
    color: $color-text;
}

.search-wrapper {
    margin: 100px auto 150px;
    width: 840px;
    background: $color-dark;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding: 12px 30px 40px;
    border-radius: 8px;
    position: relative;
    box-shadow: 0px 0px 10px 1px rgba(0, 0, 0, 0.2);

    &:before {
        content: "";
        display: inline-block;
        width: 65%;
        position: absolute;
        bottom: 2px;
        height: 0;
        z-index: -1;
        box-shadow: 0px 10px 100px 72px rgba(0, 0, 0, 0.478);
    }
}

.form {
    width: 626px;
    background: darken($color-dark, 15%);
    display: flex;
    justify-content: center;
    padding: 60px 47px 74px;
    border-radius: 16px;
}

.button {
    padding: 8px 12px;
    font-size: 16px;
    font-weight: 600;
    color: $color-gray;
    background: $color-primary;
    border-radius: 5px;
    transition: all 0.1s ease-in;

    &:hover {
        background: lighten($color-primary, 10%);
    }
}

.search-input {
    border: none;
    background: $color-text;
    border-radius: 5px;
    color: $color-dark;
    width: 100%;
    font-size: 20px;
    padding: 8px 12px 8px 37px;
    line-height: 22px;

    &::placeholder {
        color: $color-placeholder;
    }
}

.search-button {
    margin-left: 20px;
    cursor: pointer;
}

.search-icon {
    position: relative;
    right: -30px;
    font-size: 41px;
    margin-top: 2px;
    line-height: 30px;
    transform: rotateY(180deg);
    color: #AAAAAA;
}

.card-list {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    margin-bottom: 200px;
}

.card {
    $bl: '.card';

    display: flex;
    width: 21%;
    min-width: 290px;
    flex-direction: column;
    border: none;
    border-radius: 12px;
    overflow: hidden;
    background: #fefefe;
    margin: 0 18px 18px 0;
    box-shadow: 0 2px 8px 0px rgba(0, 0, 0, 0.2);

    &:hover {
        #{$bl}__image {
            transform: scale(1.1);
        }
    }

    &__image-wrapper {
        width: 100%;
        height: 218px;
        display: inline-block;
        overflow: hidden;
    }

    &__image {
        width: 100%;
        height: auto;
        transition: all 0.5s ease-in-out;
    }

    &__info-wrapper {
        padding: 20px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        height: 113px;
    }

    &__title {
        font-size: 16px;
        font-weight: 600;
        text-transform: capitalize;
    }

    &__icon {
        &_type {
            &_views {
                font-size: 13px;
            }
        }
    }

    &__tag-list {
        display: flex;
    }

    &__tag {
        background: $color-bg-tag;
        color: $color-text-dark;
        padding: 6px 9px;
        border-radius: 10px;
        margin-right: 12px;
        font-size: 14px;

        &::before {
            content: "#";
        }
    }

    &__info {
        font-size: 14px;
        margin-top: auto;
        margin-bottom: 14px;
    }
}
